<template>
  <div>
    <router-link
      class="link"
      :to="{name:'OrdersIndex'}"
    >
      Назад
    </router-link>
    <b-overlay
      :show="loading"
    >
      <div class="row">
        <div class="col-5">
          <div
            v-if="contract.status!=1"
            class="text-center"
          >
            <b-badge
              pill
              class="fs18 p-1 my-2"
              variant="light-danger"
            >
              Shartnoma bekor qilingan
            </b-badge>
          </div>
          <div v-else-if="all_credit_debt!=0">
            <div v-if="canCreate">
              <h3>
                Оформления платежа
              </h3>
              <span
                v-if="isWaiting"
                class="text-warning fs18"
              >Fayllar tekshirilmoqda. Iltimos kuting</span>
              <span
                v-else-if="isRejected"
                class="text-danger fs18"
              >Fayllar rad etildi. Iltimos tuzatib qayta yuklang</span>
              <div
                class="card p-2"
                :class="(isWaiting || isRejected)?'double-overlay':''"
              >
                <validation-observer
                  ref="form"
                  #default="{invalid}"
                >
                  <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent="submit"
                  >
                    <b-form-group
                      :label="$t('Сумма')"
                      label-for="amount"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Сумма')"
                        rules="required"
                      >
                        <cleave
                          id="amount"
                          v-model="form.amount"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :options="cleaveOption"
                          placeholder="0"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <div class="row">
                      <div class="col-6">
                        <b-form-group
                          :label="$t('Сальдо на начало')"
                          label-for="name"
                        >
                          <cleave
                            id="name"
                            :value="all_credit_debt"
                            disabled
                            class="form-control"
                            :options="cleaveOption"
                            placeholder="0"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <b-form-group
                          :label="$t('Сальдо на конец')"
                          label-for="name"
                        >
                          <cleave
                            id="name"
                            v-model="form.credit_balance"
                            class="form-control"
                            disabled
                            :options="cleaveOption"
                            placeholder="0"
                          />
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <b-form-textarea
                          id="comment"
                          v-model="form.comment"
                          class="form-control"
                          placeholder="Комментарий"
                          rows="3"
                        />
                      </div>
                      <div class="col-4">
                        <div class="mt-2 text-right">
                          <b-button
                            :disabled="!form.amount"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                          >
                            Оформить
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </validation-observer>
              </div>
            </div>
          </div>
          <!-- Клиент Info -->
          <h3>Информации клиента</h3>
          <div class="card p-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    Клиент
                  </th>
                </tr>
              </thead>
              <tbody v-if="contract && contract.lastname">
                <tr>
                  <th>ФИО</th>
                  <td>{{ [contract.lastname, contract.firstname, contract.middlename].join(' ') }}</td>
                </tr>
                <tr>
                  <th>ПИНФЛ</th>
                  <td>{{ contract.pin }}</td>
                </tr>
                <tr>
                  <th>Паспорт</th>
                  <td>{{ contract.passport }}</td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.phone }}</td>
                </tr>
                <tr>
                  <th>Скоринг информации</th>
                  <th>
                    <span
                      class="link"
                      @click="openClientScoring(contract.client_report_id)"
                    >
                      {{ [contract.lastname, contract.firstname, contract.middlename].join(' ') }}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
            <table
              v-if="contract && contract.lastname2"
              class="table table-bordered"
            >
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    Созаемщик
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>ФИО</th>
                  <td>{{ [contract.lastname2, contract.firstname2, contract.middlename2].join(' ') }}</td>
                </tr>
                <tr>
                  <th>ПИНФЛ</th>
                  <td>{{ contract.pin2 }}</td>
                </tr>

                <tr>
                  <th>Паспорт</th>
                  <td>{{ contract.passport2 }}</td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.phone2 }}</td>
                </tr>
                <tr>
                  <th>Скоринг информации</th>
                  <th>
                    <span
                      class="link"
                      @click="openClientScoring(contract.client2_report_id)"
                    >
                      {{ [contract.lastname2, contract.firstname2, contract.middlename2].join(' ') }}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- payment history -->
          <div
            v-if="payment_list && payment_list.length"
            class="card p-2 table-responsive"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="5"
                    class="text-center"
                  >
                    Статус оплаты
                  </th>
                </tr>
                <tr>
                  <th>Сумма</th>
                  <th>Дата оплаты</th>
                  <th>Комментарий</th>
                  <th>Статус</th>
                  <th>Квитанция</th>
                </tr>
              </thead>
              <tbody v-if="payment_list && payment_list.length">
                <tr
                  v-for="(payment, index) in payment_list"
                  :key="index"
                  :class="payment.type==2?'table-active':''"
                >
                  <th>{{ payment.amount|formatPrice }} so'm</th>
                  <td>{{ dateDotFormat(payment.created_at) }}</td>
                  <td>{{ payment.comment }}</td>
                  <td>
                    <b-badge
                      v-if="payment.type==2"
                      pill
                      variant="info"
                      class="mb-1 mr-1"
                    >
                      {{ $t('Первоначальный взнос') }}
                    </b-badge>

                    <b-badge
                      pill
                      :variant="payment.status==1?'info':payment.status==2?'success':'warning'"
                    >
                      <span
                        v-if="payment.status == 1 && payment.confirmed_at_1"
                      >O'rinbosar tasdiqlagan sana:
                        <b>{{ dateDotFormat(payment.confirmed_at_1) }}</b></span>
                      <span
                        v-if="payment.status == 2 && payment.confirmed_at_2"
                      >Direktor tasdiqlagan sana:
                        <b>{{ dateDotFormat(payment.confirmed_at_2) }}</b></span>
                    </b-badge>
                  </td>
                  <td>
                    <span
                      class="link"
                      @click="printCheck(payment.id)"
                    >Распечатать</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Contract info -->
          <h3>Информация о контракте</h3>
          <template v-if="contract">
            <contract-show
              :contract="contract"
              :order-id="$route.params.id"
              @boughtProductInfo="boughtProductInfo"
            />
            <ContractDetails
              :contract="contract"
              :order-id="$route.params.id"
            />
          </template>
        </div>

        <!-- График погашения -->
        <div class="col-7">
          <h3>График погашения</h3>

          <contract-schedule-table
            :contract="contract"
            contract-type="old"
          />

          <!-- Autopay Info -->
          <h3>Uni Access ma'lumotlari</h3>
          <div class="card p-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    Uni Access mijoz
                  </th>
                </tr>
              </thead>
              <tbody v-if="contract && contract.uni_access_client">
                <tr>
                  <th>ФИО</th>
                  <td
                    class="text-primary"
                    @click="
                      goTo('AutopayClient-show', {
                        id: contract.uni_access_client.id,
                      })
                    "
                  >
                    {{
                      [
                        contract.uni_access_client.lastname,
                        contract.uni_access_client.firstname,
                        contract.uni_access_client.middlename,
                      ].join(" ")
                    }}
                  </td>
                </tr>
                <tr>
                  <th>ПИНФЛ / Паспорт</th>
                  <td>
                    {{ contract.uni_access_client.pin }} /
                    {{ contract.uni_access_client.passport }}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.uni_access_client.phones.join(" ") }}</td>
                </tr>
                <tr>
                  <th>Avtomatik pul yechish</th>
                  <td>
                    <b-overlay :show="loading_checkbox">
                      <b-form-checkbox
                        v-model="contract.uni_access_client.auto"
                        :value="1"
                        :unchecked-value="0"
                        switch
                        inline
                        @input="toggleChange"
                      />
                    </b-overlay>
                  </td>
                </tr>
                <tr>
                  <th>To'lanmagan</th>
                  <td class="text-danger font-weight-bold">
                    {{
                      contract.uni_access_client.remaining_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>To'langan</th>
                  <td class="text-success font-weight-bold">
                    {{
                      contract.uni_access_client.paid_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>Jami qarzdorlik</th>
                  <td class="text-warning font-weight-bold">
                    {{
                      contract.uni_access_client.debit_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              v-if="contract && contract.uni_access_client2"
              class="table table-bordered"
            >
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    UNI ACCESS MIJOZ 2 (KAFIL)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>ФИО</th>
                  <td
                    class="text-primary"
                    @click="
                      goTo('AutopayClient-show', {
                        id: contract.uni_access_client2.id,
                      })
                    "
                  >
                    {{
                      [
                        contract.uni_access_client2.lastname,
                        contract.uni_access_client2.firstname,
                        contract.uni_access_client2.middlename,
                      ].join(" ")
                    }}
                  </td>
                </tr>
                <tr>
                  <th>ПИНФЛ / Паспорт</th>
                  <td>
                    {{ contract.uni_access_client2.pin }} /
                    {{ contract.uni_access_client2.passport }}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.uni_access_client2.phones.join(" ") }}</td>
                </tr>
                <tr>
                  <th>Avtomatik pul yechish</th>
                  <td>
                    <b-overlay :show="loading_checkbox">
                      <b-form-checkbox
                        v-model="contract.uni_access_client2.auto"
                        :value="1"
                        :unchecked-value="0"
                        switch
                        inline
                        @input="toggleChange"
                      />
                    </b-overlay>
                  </td>
                </tr>
                <tr>
                  <th>To'lanmagan</th>
                  <td class="text-danger font-weight-bold">
                    {{
                      contract.uni_access_client2.remaining_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>To'langan</th>
                  <td class="text-success font-weight-bold">
                    {{
                      contract.uni_access_client2.paid_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>Jami qarzdorlik</th>
                  <td class="text-warning font-weight-bold">
                    {{
                      contract.uni_access_client2.debit_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--  -->
          <div>
            <h3>Комментарии</h3>
            <div class="card p-2">
              <Comments />
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <!-- Scoring Modal -->
    <div v-if="clientReport">
      <b-modal
        v-model="openClientScoringModal"
        centered
        hide-footer
        no-close-on-backdrop
        @close="openClientScoringModal=false"
        @cancel="openClientScoringModal=false"
      >
        <h4 class="text-center">
          Mijoz scoring ma'lumotlari
        </h4>
        <response-show
          :form="form"
          type="clientReport"
          :client-report="clientReport"
        />
      </b-modal>
    </div>

    <!-- Bought Products Info  Modal -->
    <div v-if="openBoughtProductsModal">
      <b-modal
        v-model="openBoughtProductsModal"
        centered
        hide-footer
        size="xl"
        no-close-on-backdrop
        @close="openBoughtProductsModal=false"
        @cancel="openBoughtProductsModal=false"
      >
        <h4 class="text-center">
          Mijoz natijalari
        </h4>
        <BoughtProductsInfo
          :bought-products-info="boughtProductsInfo"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'

import ContractScheduleTable from '@/components/tables/contractSchedule.vue'
import ResponseShow from '../order/components/response_show.vue'
import Comments from './comments.vue'
import ContractShow from './show/contractShow.vue'
import ContractDetails from './show/contractDetails.vue'
import BoughtProductsInfo from './boughtProductsInfo.vue'
import ContractUpload from '../order/show.vue'

export default {
  components: {
    ValidationProvider, ValidationObserver, Cleave, Comments, BoughtProductsInfo, ContractShow, ContractUpload, ContractScheduleTable, ResponseShow, ContractDetails,
  },
  data() {
    return {
      form: {
        old_contract_id: null,
        comment: '',
        amount: null,
        credit_balance: null,
      },
      boughtProductsInfo: [],
      title: '',
      visible: true,
      openClientScoringModal: false,
      openBoughtProductsModal: false,
      clientReport: null,
      contract: null,
      loading: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      payment_list: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
    }),
    all_credit_debt() {
      return (Number(this.contract.credit_amount) - Number(this.contract.all_payment_amount))
    },
    isWaiting() {
      return (this.isUploadFiles && this.contract.images_confirmation_status == 0)
    },
    isRejected() {
      return this.contract.images_confirmation_status == 2
    },
    isConfirmed() {
      return this.contract.images_confirmation_status == 1
    },
    contractId() {
      return this.$route.params.id
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    storagePaymentsUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/payments/`
    },
    isUploadFiles() {
      if (this.contract && this.contract.client2) {
        return !!(this.contract.contract_pdf_url && this.contract.passport_url2 && this.contract.passport_address_url2
         && this.contract.passport_url && this.contract.passport_address_url)
      }
      return !!(this.contract.contract_pdf_url
       && this.contract.passport_url && this.contract.passport_address_url)
    },
    canCreate() {
      return this.hasPermission('payments.store')
    },
    canUpload() {
      return (this.hasPermission('contracts.update') && this.contract.is_rejected == 0)
    },
  },
  watch: {
    'form.amount': function (val) {
      this.form.credit_balance = this.all_credit_debt - val
      if (val > this.all_credit_debt) {
        this.form.amount = this.all_credit_debt
      }
    },
    $route() {
      this.form.old_contract_id = this.$route.params.id
      this.getAction()
    },
  },
  mounted() {
    this.form.old_contract_id = this.$route.params.id
    this.getAction()
  },
  methods: {
    ...mapActions({
      getItemAction: 'contract/oldContractShow',
      makePaymentPdf: 'resource/makePaymentPdf',
      getClientReportInfo: 'resource/getClientReportInfo',
      getClientReport: 'resource/getClientReport',
      paymentStore: 'contract/paymentStoreForOldContract',
      getContractProductInfo: 'contract/getContractProductInfo',
      paymentList: 'contract/paymentList',
    }),
    openClientScoring(clientReportId) {
      this.clientReport = null
      this.getClientReport({ id: clientReportId })
        .then(res => {
          if (res) {
            this.clientReport = res.data
          }
        })
        .finally(() => {
          this.openClientScoringModal = true
        })
    },
    boughtProductInfo(product) {
      this.getContractProductInfo({ contract_id: product.contract_id, product_category_id: product.product_category_id, relations: 'product.party.provider' })
        .then(res => {
          if (res) {
            this.boughtProductsInfo = res.data
          }
        })
        .finally(() => {
          this.openBoughtProductsModal = true
        })
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    getStatuses(status) {
      switch (status) {
        case 1:
          return "O'rinbosar tasdiqlagan"
        case 2:
          return 'Direktor tasdiqlagan'

        default:
          return 'Tasdiqlanmagan'
      }
    },
    async submit() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.paymentStore(this.form).then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно оформлен'))
            this.form.amount = 0
            this.form.comment = ''
            this.getAction()
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    getAction() {
      this.getItemAction({ id: this.$route.params.id, relations: 'payments|rejectedUser|uniAccessClient|uniAccessClient2' })
        .then(resp => {
          if (resp.success) {
            this.contract = resp.data
            this.payment_list = resp.data.payments
          }
        })
    },
    printCheck(id) {
      this.makePaymentPdf(id).then(res => {
        this.contract_path = `${this.storagePaymentsUrl + res.data}.pdf`
        window.open(`${this.storagePaymentsUrl + res.data}.pdf`, '_blank')
        // this.downloadFile()
        // this.downloadAxiosFile()
      })
    },
    closeUpload() {
      this.visible = false
      this.getAction()
    },
  },

}
</script>

<style>

</style>
